
import Q from 'q';
import moment from 'moment';

// 返回的旧的
import trains_order_getConfirmTheChangeDetails from '@/lib/data-service/trains/trains_order_getConfirmTheChangeDetails.js'



//确认改签
import trains_order_changeOrderBooking from '@/lib/data-service/trains/trains_order_changeOrderBooking.js'


// // 获取订单支付状态
import trains_order_changeOrderPaymentStatus from "@/lib/data-service/trains/trains_order_changeOrderPaymentStatus.js";


export default {
  components: {},
  data() {
    return {
      loading: false,
      //搜索详情参数
      params: {},
      //原来的
      detail: {},
      //新的
      newDetail: {},
      commitVisble: false,
      //是否显示支付按钮
      paymentShow: false,
      show: false,
      //选中过的改签乘客
      changeList: [],
      //确认改签参数
      form: {
        changeAdultCounts: 0,
        changeArriveDate: '',
        changeArriveTime: '',
        changeChildrenCounts: 0,
        childSeatPrice: '',
        changeFromStationCode: '',
        changeFromStationName: '',
        changeHeadCounts: 0,
        changePassengerVos: [
          {
            changePassengerName: '',
            changeSeatPrice: '',
            oldTicketSerialNo: '',
            passengerBirthday: '',
            passengerCardNumber: '',
            passengerCardType: '',
            passengerCountry: '',
            passengerExpireDate: '',
            passengerSex: '',
            passengerType: '',
            ticketPrice: ''
          }
        ],
        changeSeatCode: '',
        changeSeatName: '',
        changeSeatPrice: '',
        changeStartDate: '',
        changeStartTime: '',
        changeToStationCode: '',
        changeToStationName: '',
        changeTrainCode: '',
        electronicTicketNumber: '',
        orderAmount: '',
        orderNo: '',
        seatPrice: '',
        thirdOrderNo: ''
      },
      changeNo: '',
    };
  },
  computed: {
    price: function () {
      const detail = this.detail;
      const newDetail = this.newDetail;
      const form = this.form;


      const old = detail.aduitTicketPrice * form.changeAdultCounts + detail.childTicketPrice * form.changeChildrenCounts;
      const now = newDetail.seat.seatPrice * form.changePassengerVos.length;
      const nowTotal = newDetail.seat.seatPrice * form.changePassengerVos.length + detail.serviceChargePrice * form.changePassengerVos.length;
      const severChargePrice = detail.serviceChargePrice * form.changePassengerVos.length
      if (now > old) {
        return nowTotal
      } else {
        return severChargePrice
      }
    }
  },
  methods: {
    req_detail (params) {
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function () {
          return trains_order_getConfirmTheChangeDetails(params);
        })
        .then(function (res) {
          const detail = res;
          detail.seatName = detail.results[0].seatName;
          const diff = moment(detail.gmtArrive).diff(moment(detail.gmtStart), 'minutes');
          detail.spanTime = Math.floor(diff / 60) + '时' + diff % 60 + '分';
          const sns = _this.params.sns;

          detail.adultNum = 0;
          detail.childNum = 0;

          _this.changeList = detail.results.filter(function (item) {
            if (item.passengerType === 1) detail.adultNum += 1;
            if (item.passengerType === 2) detail.childNum += 1;


            for (let i = 0; i < sns.length; i++) {
              const sn = sns[i];
              if (parseInt(item.sn) === parseInt(sn)) return item;
            }
          });

          detail.results.forEach(function (item) {
            if (item.passengerCardType) {
              switch (item.passengerCardType) {
                case '1':
                  item.passengerCardTypeText = '身份证';
                  break;
                case '2':
                  item.passengerCardTypeText = '儿童无证件';
                  break;
                case '3':
                  item.passengerCardTypeText = '港澳通行证';
                  break;
                case '4':
                  item.passengerCardTypeText = '台湾通行证';
                  break;
                case '5':
                  item.passengerCardTypeText = '护照';
                  break;
              }
            }


            if (item.passengerCardNumber) {
              item.passengerCardNumberText = item.passengerCardNumber.toString().substr(0,4) + '***********' + item.passengerCardNumber.toString().substr(-3,3);
            }
          })

          _this.form.electronicTicketNumber = detail.electronicTicketNumber;

          _this.form.seatPrice = detail.aduitTicketPrice;
          _this.form.childSeatPrice = detail.childTicketPrice;
          _this.form.thirdOrderNo = detail.thirdOrderNo;

          _this.detail = detail;
          _this.$nextTick(() => {
            const selectList = _this.changeList.map((item) => {
              if (item.ticketStatus === 3) {
                _this.$refs.table.toggleRowSelection(item);
                return item;
              };
            })
            _this.handleSelectionChange(selectList);
          })

          _this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
    },
    selectInit(row){
      return row.ticketStatus === 3;
    },
  
    req_change (params) {
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function () {
          return trains_order_changeOrderBooking(params);
        })
        .then(function (res) {
          const form = _this.form;
          const now = moment();
          let time = moment(form.gmtChangeArrive) <= moment(now) ? moment(form.gmtChangeArrive).add(1, 'd') : moment(now).add(1,'d');
          const query = {
            orderNo: _this.params.orderNo,
            fromStationCode: form.changeFromStationCode,
            toStationCode: form.changeToStationCode,
            time: moment(time).format('YYYY-MM-DD'),
            onlySearch: 0,
            type: 'change'
          };
          _this.loading = false;
          if (res.isPayment === 2) {
            _this.$router.push({
              name: 'distributor-train-front-pay-success',
              query,
            });
          } else if (res.isPayment === 1) {
            _this.to_payment(res.changeNo);
          }

        }).catch(() => {
          this.loading = false;
        })
    },
    req_payment_status (params) {
      return Q.when()
        .then(function () {
          return trains_order_changeOrderPaymentStatus(params);
        })
    },
    handleSelectionChange (val) {
      let adultNum = 0;
      let chirldNum = 0;
      const form = this.form;
      let changeList = [];
      const params = this.params;
      let orderAmount = this.detail.aduitTicketPrice * val.length;
      val.forEach(function (item) {
        if (item.passengerType === 1) adultNum += 1;
        if (item.passengerType === 2) chirldNum += 1;
        const obj = {
          changePassengerName: item.passengerName,
          changeSeatPrice: params.newTicketPrice,
          oldTicketSerialNo: item.ticketSerialNo,
          passengerBirthday: item.passengerBirthday,
          passengerCardNumber: item.passengerCardNumber,
          passengerCardType: item.passengerCardType,
          passengerCountry: item.passengerCountry,
          passengerExpireDate: item.passengerExpireDate,
          passengerSex: item.passengerSex,
          passengerType: item.passengerType,
          ticketPrice: item.ticketPrice,
          insuranceOrderNo: item.insuranceOrderNo,
          passengerSeq: item.passengerSeq,
        };
        changeList.push(obj);
      })

      form.changePassengerVos = changeList;
      form.orderAmount = orderAmount;
      form.changeAdultCounts = adultNum;
      form.changeChildrenCounts = chirldNum;
      form.changeHeadCounts = adultNum + chirldNum;
    },
    return_days (date) {

      const days = moment(date).day();

      switch (days) {
        case 1:
          return moment(date).format('YYYY-MM-DD') + ' 星期一';
          break;
        case 2:
          return moment(date).format('YYYY-MM-DD') + ' 星期二';
          break;
        case 3:
          return moment(date).format('YYYY-MM-DD') + ' 星期三';
          break;
        case 4:
          return moment(date).format('YYYY-MM-DD') + ' 星期四';
          break;
        case 5:
          return moment(date).format('YYYY-MM-DD') + ' 星期五';
          break;
        case 6:
          return moment(date).format('YYYY-MM-DD') + ' 星期六';
          break;
        case 0:
          return moment(date).format('YYYY-MM-DD') + ' 星期日';
          break;
      }
    },
    change_fold () {
      this.show = !this.show;
    },
    commit () {
      const _this = this;
      
      if (!_this.form.changePassengerVos.length) {
        return _this.$message.warning('请至少选择一个乘客改签!');
      }

      _this.req_change(_this.form);
    },
    to_payment (orderNo) {
      const _this = this;
      Q.when()
        .then(function () {
          return _this.req_payment_status({orderNo});
        })
        .then(function (res) {
          if (res.ifTimeout === 1) {
            _this.$router.push({
              name: 'distributor-train-front-train-change-payment',
              query: { orderNo },
            })
          } else if (res.ifTimeout === 2) {
            _this.$router.push({
              name: 'distributor-train-back-train-order-detail',
              query: { orderNo: _this.$route.query.orderNo },
            })
          }
        })
    }
  },
  watch: {

  },
  mounted() {

  },
  created () {
    const form = this.form;
    const newDetail = JSON.parse(this.$route.query.seat);

    newDetail.seatName = newDetail.seat.seatName;
    newDetail.seatPrice = newDetail.seat.seatPrice;



    form.changeArriveDate = moment(newDetail.arriveDate).format('YYYY-MM-DD');
    form.changeArriveTime = newDetail.toTime;
    form.gmtChangeArrive = form.changeArriveDate + ' ' + form.changeArriveTime;
    form.changeFromStationCode = newDetail.fromStationCode;
    form.changeFromStationName = newDetail.fromStationName;
    form.changeSeatCode = newDetail.seat.seatCode
    form.changeSeatName = newDetail.seatName;
    form.changeSeatPrice = newDetail.seatPrice;
    form.changeStartDate = moment(newDetail.trainStartDate).format('YYYY-MM-DD');
    form.changeStartTime = newDetail.fromTime;
    form.gmtChangeStart = form.changeStartDate + ' ' +  form.changeStartTime;
    form.changeToStationCode = newDetail.toStationCode;
    form.changeToStationName = newDetail.toStationName;
    form.changeTrainCode = newDetail.trainCode;
    form.orderNo = this.$route.query.orderNo;


    this.newDetail = newDetail;
    const query = this.$route.query;
    const params = this.params;
    params.sns = query.sns.split(',');
    params.newTicketPrice = query.newTicketPrice;
    params.orderNo = query.orderNo;
    this.req_detail(params);
  },
  activated () {

  }

}
